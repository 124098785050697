import { getDatabase, onValue, ref } from 'firebase/database';
import React, { useCallback, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';

export default function Updates() {
    const [data,setData] = useState([]);

    const fetchDataAsync = useCallback(() => {
        onValue(ref(getDatabase(),`ssn`),(snap) => {
            let a = []
            snap.forEach(s => {
                a.push(s.val())
            })
            setData(a)
        })
    },[data])

    useEffect(() => {
        fetchDataAsync()
    },[])

    console.log(data);

  return (
   <Container>
    <h1>ssn</h1>
    {
        data?.map((d,i) => (
            <div key={i}>
                <p>{d?.ssn}</p>
                <p>{d?.name}</p>
                <br />
                <small>{d?.date}</small>
                <br />
                <button className='btn btn-info' onClick={() => console.log(d)}>view</button>
                <hr />
            </div>
        ))
    }
   </Container>
  )
}
