import { Container } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';

function Services() {
  return (
   <Container> 
    <h1>Services</h1>
     <ListGroup>

      <ListGroup.Item href='/creditrepair' action>Credit Repair</ListGroup.Item>
      <ListGroup.Item href='/claim' action>Worker's Compensation</ListGroup.Item>
      <ListGroup.Item href='/student' action>International Student Consulting</ListGroup.Item>
      <ListGroup.Item href='/loan' action>Business Loan</ListGroup.Item>
      <ListGroup.Item href='/personal' action>Personal loan</ListGroup.Item>
      <ListGroup.Item href='/company' action>Register New Company</ListGroup.Item>
      <ListGroup.Item href='/upload' action>Apply for Credit Card, Debit Card, Bank Account</ListGroup.Item>

      <ListGroup.Item href='/privacy' action>Privacy Policy</ListGroup.Item>
     <ListGroup.Item href='/terms' action>Terms and Conditions</ListGroup.Item>
     <ListGroup.Item href='/eula' action>EULA</ListGroup.Item>

    </ListGroup>
   </Container>
  );
}

export default Services;